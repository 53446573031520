import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Util } from '@utils/utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CertificadosService {

  hayBeneficiario: boolean = null;
  hayIngresoBase: boolean = null;

  constructor(private http: HttpClient,
    private util: Util) { }

  public obtenerCertificados(rut: string, periodo: string) {
    let url = `${environment.BASE_BACKEND}/certificado`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    const data = {
      rut,
      periodo
    }
    const body = { token: this.util.encrypt(JSON.stringify(data)) };
    return this.http.post(url, body, { headers, params });
  }

  public obtenerPeriodos(): Observable<any> {
    let url = `${environment.BASE_BACKEND}/certificado/tributario/periodos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.http.get(url, { headers, params });
  }

  public obtenerBeneficiarios(rut: string): Observable<any> {
    let url = `${environment.BASE_BACKEND}/certificado/beneficiarios/carga-familiar`;
    let body = { token: this.util.encrypt( rut ) }
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.http.post(url, body,{ headers, params });
  }

  public compruebaBeneficiarios( rut: string  ){
    this.obtenerBeneficiarios(this.util.rutCleanAConnect(rut))
        .subscribe(beneficiarios => {
          this.hayBeneficiario = beneficiarios.length > 0 ? true : false ; 
              
        }, () => this.hayBeneficiario = false );
  }

  public obtenerHayIngresoBase(rut: string){
    let url = `${environment.BASE_BACKEND}/certificado/hay-ingreso-base/obtener`;
    let body = { token: this.util.encrypt( JSON.stringify({rut, canal: environment.canal}) ) }
    let headers = new HttpHeaders();
    let params = new HttpParams();
    this.http.post(url, body,{ headers, params }).subscribe( hay => this.hayIngresoBase = hay['value'], () => this.hayIngresoBase = false );
  }


}

