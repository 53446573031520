import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Util } from '@utils/utils';

@Injectable({
  providedIn: 'root'
})
export class BitacoraService {

  bitacoraChanged: boolean = true;
  bitacoraList: any = [];

  ryqBuscado: boolean = false;
  ryqList: any = [];
  

  constructor(private http: HttpClient,
    private readonly util: Util) { 
    
  }

  public getBitacoraCliente(rut: string, fechaAtencionInicio: string, fechaAtencionFin: string) {
    let url = `${environment.BASE_BACKEND}/historicos/bitacora`;
    let headers = new HttpHeaders();
    const params = {
      rut,
      fechaAtencionInicio,
      fechaAtencionFin
    }
    const body = {
      token: this.util.encrypt(JSON.stringify(params))
    };
    return this.http.post(url, body, { headers });
  }

  public getBitacoraRyQ(rut: string) {
    let url = `${environment.BASE_BACKEND}/historicos/ryq`;
    let headers = new HttpHeaders();
    const params = {
      rut
    }
    const body = {
      token: this.util.encrypt(JSON.stringify(params))
    };
    return this.http.post(url, body, { headers });
  }

  cleanBitacoraSearch(){
    this.bitacoraChanged = true;
    this.ryqBuscado = false;
    this.bitacoraList = [];
    this.ryqList = [];
  }
  
}
