import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "@services/authentication.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {


  constructor(
    private authSrvs: AuthenticationService,
  ) {
    document.onclick = function() {
      if ( window.location.pathname !== '/login'){
        authSrvs.nuevoTimestampSesion();
      }
    }
  }

  ngOnInit() {
  }

}
