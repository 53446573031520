import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Routes } from '@angular/router';
import { Observable } from 'rxjs';

import { NavController } from '@ionic/angular';

import { LOGIN_URL } from "@constants/url.constant";
import { StorageService } from "@services/storage.service";
import { MsalService } from '@azure/msal-angular';
import { AuthenticationService } from '@services/authentication.service'


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private navCtrl: NavController,
    private storageSrvs: StorageService,
    private msalSrvs: MsalService,
    private authSrvs: AuthenticationService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
    if ( this.msalSrvs.instance.getActiveAccount() === null && !this.authSrvs.isQA ){
      this.navCtrl.navigateRoot(LOGIN_URL);
      return false;
    }else{
      this.authSrvs.nuevoTimestampSesion();
    }

    return true;

  }
  
}
