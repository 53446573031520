import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RentabilidadService {

  private rentabilidadSubject = new BehaviorSubject<any>(null);

  constructor() { }

  public setRentabilidad(rentabilidad) {
    this.rentabilidadSubject.next(rentabilidad);
  }

  public getRentabilidad() {
    return this.rentabilidadSubject.asObservable();
  }

}
