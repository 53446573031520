import { Injectable } from '@angular/core';
import { environment } from '@env';
import { ICuentasCombo } from '@interfaces/default.interface'

import { PRODUCTOS, MESES } from "@constants/general.constant";

import { clean, format } from 'rut.js';

import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import JSEncrypt  from "jsencrypt";


const TIMEZONE = 'America/Santiago';
const COMPLETE_DATE_LONG = 'DD MMMM YYYY';
const COMPLETE_DATE_TIME_LONG = 'DD MMMM YYYY HH:mm';
const YEARMONTH = 'YYYYMM'

@Injectable()
export class Util {

  constructor(
    
  ) { }
  
  public rutCleanAConnect(rut: string): string {
    return rut.replace(/\./g, '');
  }
  public rutClean(rut: string): string {
    return clean(rut);
  }
  public rutFormato(rut: string): string {
    return format(rut);
  }

  public formatDateLong( date: string): string{
    let fecha = moment(date, "DD/MM/YYYY").locale('es');
    return fecha.tz(TIMEZONE).format(COMPLETE_DATE_LONG);
  }

  public nuevoTiempoSesion(): number{
    return new Date().getTime() + environment.controlSesion.tiempo_sesion;
  }
  public nuevoTiempoAlertaSesion(): number{
    return new Date().getTime() + environment.controlSesion.tiempo_aviso;
  }

  public timestampActual(): number{
    return new Date().getTime();
  }

  public fechaDesdeTimeStamp( date: any ){
    let fecha = moment( +date ).locale('es');
    return fecha.tz(TIMEZONE).format(COMPLETE_DATE_TIME_LONG);
  }

  public timestampSumaDias( dias: string ){
    return this.timestampActual()+(+dias*(1000*60*60*24));
  }

  public fechasAnoPeriodo(){
    let date = new Date();
    let fechaDesde = moment(date).subtract(1, 'years').tz(TIMEZONE).format("YYYY-MM-DD");
    let fechaHasta = moment(date).tz(TIMEZONE).format("YYYY-MM-DD");
    
    return { fechaDesde, fechaHasta };
  }

  public longDateEsp( fecha: string){
    let dia = fecha.substring(0,2);
    let mes = fecha.substring(3,5);
    let ano = fecha.substring(6);

    return dia+' de '+MESES[(+mes)-1] +' de '+ano;
  }

  public fechaMesAno( fecha: string ){
    let m = fecha.substring(3, 5);
    return MESES[+m-1]+ ' ' +fecha.substring(6, 10);
  }

  public fechaLarga( fecha: string): string {
    let arrayFecha = fecha.split('/');
    let mes = MESES[+arrayFecha[1]-1];
    return arrayFecha[0]+' de '+mes+' '+arrayFecha[2];
  }

  public completarConCeros(number, size) {
    const numberOutput = Math.abs(number);
    const length = number.toString().length;
    const zero = "0";
    
    if (size <= length) {
        if (number < 0) {
             return ("-" + numberOutput.toString()); 
        } else {
             return numberOutput.toString(); 
        }
    } else {
        if (number < 0) {
            return ("-" + (zero.repeat(size - length)) + numberOutput.toString()); 
        } else {
            return ((zero.repeat(size - length)) + numberOutput.toString()); 
        }
    }
}

  public arrayCuentasCliente(cuentasCombo: ICuentasCombo[]){

    let cuentas: string[] = [];

    cuentasCombo.forEach( cuenta => {
      cuentas.push( cuenta.producto );
    });

    return cuentas;
  }

  public colorEstado( estado: string): string{
    let color = '#92F1AB'
    switch (estado) {
      case 'T':
        color = '#92F1AB';
        break;
      case 'AP':
        color = '#92F1AB';
        break;
      case 'RE':
        color = '#FFB2BC';
        break;
      case 'R':
        color = '#FFB2BC';
        break;
      case 'I':
        color = '#FFE094';
        break;
      case 'DI':
        color = '#FFE094';
        break;
    }
    return color;
  }

  public obtieneEstado( estado: string){
    let estadoDescrip = '';
    switch (estado) {
      case 'T':
        estadoDescrip = 'TERMINADA'
        break;
    
      case 'AP':
        estadoDescrip = 'APROBADA'
        break;
    
      case 'RE':
        estadoDescrip = 'RECHAZADA'
        break;
      case 'R':
        estadoDescrip = 'RECHAZADA'
        break;
      case 'I':
        estadoDescrip = 'INGRESADA'
        break;
      case 'DI':
        estadoDescrip = 'DIGITADA'
        break;
    
      default:
        estadoDescrip = 'RRDDGG'
        break;
    }
    return estadoDescrip;
  }

  public quitarAcentos(value) {
    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  }

  public findProduct(codigo: string) {
    const producto = PRODUCTOS.find(x => x.codigo === codigo.toUpperCase());
    return producto ? producto.nombre : '';
}
  // funcion para transformar el formato de fecha entregado por el servicio de bono de reconocimiento
  public pipeFechaBono(fecha: string){
    return fecha.substring(0, 10);
  }

  public peridoPagoPension(){
    let fecha = new Date();
    let ano:string = fecha.getFullYear().toString();
    let mes:string = ("0" + (fecha.getMonth() + 1)).slice(-2)
    return ano + mes;
  }

  public periodoCertificados( meses:number ){
    let hasta = moment( new Date() ).tz(TIMEZONE).format(YEARMONTH);
    let desde = moment( new Date() ).subtract( meses-1, 'months' ).tz(TIMEZONE).format(YEARMONTH);

    if ( meses === 0 ){
      desde = '198101'
    }  
    return { desde, hasta };
  }

  public browserDetect(){
    if ( navigator.userAgent.indexOf('Firefox') >= 0 ){
      return 'Firefox';
    }else if (navigator.userAgent.indexOf('OPR/') >= 0){
      return 'Opera';
    }else if (navigator.userAgent.indexOf('Chrome/') >= 0){
      return 'Chrome';
    }else if (navigator.userAgent.indexOf('Safari/') >= 0){
      return 'Safari';
    }else if (navigator.userAgent.indexOf('MSIE') >= 0){
      return 'Internet Explorer';
    }else {
      return 'navigator.userAgent';
    }
  }

  public encrypt( data: any ) {
    let encrypt = new JSEncrypt;
    encrypt.setPublicKey( environment.PUBLIC_KEY );
    return encrypt.encrypt( data );
  }

  public fromDateToText (date: Date, separator: string = '-', restaMes = 0) {
    const year = date.getFullYear();
    const month = ((date.getMonth() + 1) - restaMes);
    const day = date.getDate();
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const dayValue = (day < 10 ? '0' + day : day);
    const monthValue = (month < 10 ? '0' + month : month);
    const hourValue = hour < 10 ? '0' + hour : hour;
    const minuteValue = minutes < 10 ? '0' + minutes : minutes;
    const secondsValue = seconds < 10 ? '0' + seconds : seconds;
    if (separator == '-') {
        return `${year}-${monthValue}-${dayValue} ${hourValue}:${minuteValue}:${secondsValue}`;
    }
    return `${dayValue}/${monthValue}/${year} ${hourValue}:${minuteValue}:${secondsValue}`;
}

public fromDateToTextWithoutTime (date: Date, separator: string = '-', restaMes = 0) { 
    const response = this.fromDateToText(date, separator, restaMes);
    return response.substring(0, 10);
}

public fromTextToDate(text: string) {
  if (!text) return null;

  const year = +text.substring(0, 4);
  const month = +text.substring(5, 7) - 1;
  const day = +text.substring(8, 10);
  return new Date(year, month, day);
}


}