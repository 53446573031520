export const HOME_URL = 'home';
export const LOGIN_URL = 'login';
export const APLICATIVOS_URL = 'aplicativos';
export const TRANSACCION_DETALLE = 'transacciondetalle';
export const MULTIPLICADOR_APORTES = 'multiplicadoraporte';
export const CLAVE_PROVISORIA = 'claveprovisoria';
export const CERTIFICADOS = 'certificados';
export const CERTIFICADO_ENVIAR = 'certificadoenviar';
export const CARTOLAS = 'cartolas';
export const BENEFICIARIO_ELIMINAR = 'beneficiarios/eliminar/:id';
export const BENEFICIARIO_EDITAR = 'beneficiarios/editar/:id';
export const DATOS_CONTACTO_EDITAR = 'datos-contacto/editar';
export const INFO_BANCARIA_EDITAR = 'info-bancaria-editar';
export const DATOS_PERSONALES_EDITAR = 'editar-datos-personales';
export const CERTIFICADO_PERIODO = 'certificado-periodo';
export const PENSION = 'pension';
export const SUCURSALES = 'sucursales';
export const SUCURSAL_EDIT = 'sucursaleditar'
export const HISTORY = 'history';
