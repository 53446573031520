import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { Util } from '@utils/utils'


// import { MsalService } from '@azure/msal-angular';

import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService implements HttpInterceptor {

  constructor(
    private authSrvs: AuthenticationService,
    private util: Util,
  ) {
   }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if ( req.url.indexOf('/prelogin') > 0){
      return next.handle(req);
    }else{
      let session = this.authSrvs.getSesionBuffer();
      let datos = {
        iat: Date.now() / 1000,
        exp: (Date.now() / 1000) + 30,
        username: this.authSrvs.getUsername(),
        oid: session.idTokenClaims['oid'],
        tid: session.tenantId,
        appid: session.idTokenClaims['aud'],
        app_displayname: environment.app_name,
      }
      
      let ecript = this.util.encrypt( JSON.stringify(datos) );
      
      const headers = new HttpHeaders ({
        canal: 'web',
        browser: this.util.browserDetect(),
        version_front: environment.version,
        email_ejecutivo: this.authSrvs.getUsername(),
        xafp_key: ecript.toString(),
        Authorization: 'Bearer '+this.authSrvs.getToken()
      });
      const reqClone = req.clone({headers});
      return next.handle(reqClone);

    }

  }

  

}
