import { Injectable } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-common';
import { environment } from '@env';
import { HOME_URL, LOGIN_URL } from '@constants/url.constant';

import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Util } from '@utils/utils'
import _ from 'lodash';

import { ClienteService } from "./cliente.service";

import { ModalCierreSesionComponent } from "@components/modal-cierre-sesion/modal-cierre-sesion.component";
import { StorageService } from "@services/storage.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  sesionTimestamp: number = null; // para establecer el tiempo de sesion
  alertaSesionTimestamp: number = null; // para establecer el tiempo de alerta de cierre sesion
  modalAlertaSesionPresente: boolean = false;
  authInterval = null; // para verificar cada cierto tiempo la sesion
  refreshTokenInterval = null; // para actualizar el access token
  sesionExpirada: boolean = false;
  modal:any = null;
  
  accestoken: any = null;
  public user: string = '';
  isQA: boolean = false;
  userQA: object = null;

  constructor(
    private msalSrvs: MsalService,
    private navCtrl: NavController,
    private http: HttpClient,
    private util: Util,
    private clienteSrvs: ClienteService,
    public modalController: ModalController,
    private StorageSrvs: StorageService,

  ) {
   
    if ( this.isLoggedIn() || this.isQA ){
      this.activaControldeSesion();
      if (this.isLoggedIn()){
        this.refreshTokenMsal();
      }
      this.user = this.getUsername();
    }
    
  }

  isLoggedIn(): boolean {
    return this.msalSrvs.instance.getActiveAccount() !== null;
  }

  login(){
      this.userQA = null;
      this.msalSrvs.loginPopup().subscribe( (response: AuthenticationResult) => {
        // console.log('response loginPopup: ', response);
        this.StorageSrvs.sesionSet(response.uniqueId+'.'+response.tenantId+'-acct', response.accessToken);
        this.sesionExpirada = false;
        this.activaControldeSesion();
        this.msalSrvs.instance.setActiveAccount( response.account);
        this.user = this.getUsername();
        this.refreshTokenMsal();
        this.navCtrl.navigateRoot(HOME_URL);
        this.sesionlog();
      });
    
  }

  logout(){
    this.clienteSrvs.cleanBuscar(true);
    this.msalSrvs.instance.setActiveAccount(null);
    this.StorageSrvs.sesionClear();
    clearInterval(this.authInterval);
    clearInterval(this.refreshTokenInterval);
    this.isQA = false;
    this.navCtrl.navigateRoot(LOGIN_URL);
  }

  getName(): string {
    if ( this.isQA ){
      return _.get( this.userQA, 'name', 'Usuario QA');
    }else{      
      return this.msalSrvs.instance.getActiveAccount().name;
    }
  }
  getUsername(): string {
    if ( this.isQA ){
      return _.get( this.userQA, 'username', 'qausuario@sura.cl');
    }else{
      // console.log( 'accestoken - ', this.StorageSrvs.sesionGet( this.msalSrvs.instance.getActiveAccount().homeAccountId+'-acct' ));
      return this.msalSrvs.instance.getActiveAccount().username;
    }
  }

  refreshTokenMsal(){
    let currentAccount = this.msalSrvs.instance.getAccountByUsername(this.getUsername());
      this.msalSrvs.instance.acquireTokenSilent({
        scopes: ["Directory.ReadWrite.All", "openid", "Policy.Read.All", "Policy.Read.ConditionalAccess", "Policy.ReadWrite.ConditionalAccess", "profile", "User.Invite.All", "User.Read.All", "User.ReadWrite.All"],
        account: currentAccount,
        forceRefresh: true
      }).then( response => {
        this.StorageSrvs.sesionSet(response.uniqueId+'.'+response.tenantId+'-acct', response.accessToken);
      });

    this.refreshTokenInterval = setInterval( function(){
      let currentAccount = this.msalSrvs.instance.getAccountByUsername(this.getUsername());
      this.msalSrvs.instance.acquireTokenSilent({
        scopes: ["Directory.ReadWrite.All", "openid", "Policy.Read.All", "Policy.Read.ConditionalAccess", "Policy.ReadWrite.ConditionalAccess", "profile", "User.Invite.All", "User.Read.All", "User.ReadWrite.All"],
        account: currentAccount,
        forceRefresh: true
      }).then( response => {
        this.StorageSrvs.sesionSet(response.uniqueId+'.'+response.tenantId+'-acct', response.accessToken);
      });
    }.bind(this), 3600000);
    
  }

  getSesionBuffer(){
    return this.msalSrvs.instance.getActiveAccount();
  }

  getToken(){
    return this.StorageSrvs.sesionGet( this.msalSrvs.instance.getActiveAccount().homeAccountId+'-acct' );
  }

  preLogin( token: string ): Observable<any> {
    let url = `${environment.BASE_BACKEND}/auth/prelogin`
    let headers = new HttpHeaders({ 'token': token });
    let opcions = { headers}
    return this.http.get( url, opcions );
  }

  nuevoTimestampSesion() {
    this.sesionTimestamp =  this.util.nuevoTiempoSesion();
    this.alertaSesionTimestamp = this.util.nuevoTiempoAlertaSesion();
  }

  activaControldeSesion() {
    this.nuevoTimestampSesion();
    this.authInterval = setInterval( async function(){
      // console.log('verifica sesion  actual ->', this.util.timestampActual(), ' sesion -> ', this.sesionTimestamp );
      if( this.util.timestampActual() >= this.sesionTimestamp ){
        this.sesionExpirada = true;
        if ( this.modal != null ){
          this.modal.dismiss();
        }
        this.logout();
      }else if ( this.util.timestampActual() >= this.alertaSesionTimestamp ){
        if ( !this.modalAlertaSesionPresente ){
          this.modalAlertaSesionPresente = true;
          this.modal = await this.modalController.create({
            component: ModalCierreSesionComponent,
            cssClass: 'cierre-sesion__modal-content',
          });
          await this.modal.present();
          const { data } = await this.modal.onWillDismiss();
          this.modalAlertaSesionPresente = false;
          if (!data){
            this.logout();
          }

        }
      }

    }.bind(this), environment.controlSesion.ciclo_revision);
  }

  public tokenEstimador( rut: string, email: string ){
    let body = { rut: rut, email: email }
    let url = `${environment.BASE_BACKEND}/auth/token`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    
    return this.http.post(url, body, { headers, params }) as Observable<any[]>
  }

  obtenerPerfil(){
    let grupos: any[] = this.getSesionBuffer().idTokenClaims['groups'];
    if ( grupos.find( grupo => grupo === environment.SAML_CONFIG.group_ejecutivo2 ) ){
      return 'Supervisor';
    }
    if ( grupos.find( grupo => grupo === environment.SAML_CONFIG.group_ejecutivo ) || grupos.find( grupo => grupo === environment.SAML_CONFIG.group_ejecutivo3 ) ){
      return 'Ejecutivo';
    }
    return 'Ejecutivo';
  }

  private sesionlog(){
    let url = `${environment.BASE_BACKEND}/auth/sesion`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    this.http.put(url, {}, { headers, params }).subscribe();
  }

}
