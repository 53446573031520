export const environment = {
  app_name: 'OmniCanal',
  production: false,
  version: '1.5.2',
  ENV: 'dev',
  canal: 'AFP',
  controlSesion: {
    tiempo_sesion: 1500000,
    tiempo_aviso:  900000,
    ciclo_revision: 2000,
  },
  SAML_CONFIG: {
    clientId: 'c992e8bc-4e10-4851-8e57-4bdce87bbf1f',
    redirectUri: 'https://afpejecutivo-dev.afpcapital.cl/home',
    authority: 'https://login.microsoftonline.com/organizations',
    group_ejecutivo: 'e5b9966f-30d0-49d5-a4f6-a5ac0f814da3',
    group_ejecutivo2: '598fb480-3ce4-45c1-b60c-6e33edf7bdd0',
    group_ejecutivo3: '06f960c4-634a-442f-99ec-b7a9d4a0179f',
  },
  recaptcha: {
    sitekey: '6LdjTsAeAAAAAPGUdtSP02gM98ONtNy5NlldQgQL'
  },
  BASE_BACKEND: 'https://afpejecutivobff-dev.afpcapital.cl',
  URL_ESTIMADOR_PENSIONES: 'https://estimador-qa.afpcapital.cl/external/login',
  URL_ESTIMADOR_PENSIONES_LOGIN: 'https://estimador-qa.afpcapital.cl/private/executive/login',
  PUBLIC_KEY: `-----BEGIN PUBLIC KEY-----MIICITANBgkqhkiG9w0BAQEFAAOCAg4AMIICCQKCAgBeCqWdHcjvTcvKvHDV3NktUkj91ethbRzeDN9wmKv4jHgEtL49ygfqBWlGyfORHweOJFa/Mt8oYkh7pfC4ivVHk2dJMdRz5sopSI+9DQr/+XP19QhqVxGLDUqeJ9fvdmiBs2yCFdJLZG4PcgLmO0x+5QzTfCfqK1+3aBF4HdCxEJlcmUxO6es4v+tKLfw2uidQDXQR0QyxARV1xWqITg314LQ21i8W/Q3E1HmFeN3xzSPOAytp4GUyojg4OStUo2Y0LtU753DEObkp1uJ5g1nChk/2genMJJrLZkhhcK8D0t4ZmMPAA69hwBa1Fus3a9mGy4AYbLlItDlmPehZc33hUz0zUXrnUbZ+/1jelJTgIckHmVtpxSTZLjshNkRNVBMpY8G+qIx9JePXqVOFC8XmSOm2I608omay+HVV7D4oT9P4vyObiyiX3flHuP/7pKK0qzqh0z/ShE+cLLZ3z8XSYjDQwiMkkaT+gihOtItOC6oXC8o2FBvP1lHVPCb0JgwKgEzBciXg1BrRiAscAIvZlBnLGHlQV8Xb3soQpGol46SSUL0ymYdHxgESWztetMp84PGfmEykKJvtclZs53eUjgSvfddyr8XFUgmtjgPujGpjXSuzU6VJ0qqd/Tqrja7Huqwy24WlgNHTBZSY0NTsuoHfduoRXrsfKH/U3hIfSwIDAQAB-----END PUBLIC KEY-----`
};
