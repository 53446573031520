import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  public get(key:string){
    return localStorage.getItem(key);
  }

  public set(key:string,value:string){
    localStorage.setItem(key, value);
  }

  public clear(){
    localStorage.clear();
  }

  public sesionSet( key: string, value:string ){
    sessionStorage.setItem(key, value);
  }

  public sesionGet( key: string){
    return sessionStorage.getItem(key);
  }
  public sesionClear(){
    sessionStorage.clear();
  }

}
