import { Component, OnInit,ViewEncapsulation} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-modal-cierre-sesion',
  templateUrl: './modal-cierre-sesion.component.html',
  styleUrls: ['./modal-cierre-sesion.component.scss'],
})
export class ModalCierreSesionComponent implements OnInit {

  constructor(
    public modal: ModalController,

  ) { }

  ngOnInit() {}

  cancel() {
    this.modal.dismiss(false);
  }

  ok() {
    this.modal.dismiss(true);
  }

}
