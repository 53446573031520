import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Util } from '@utils/utils';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BeneficiariosService {

  private beneficiarioSeleccionado$ = new BehaviorSubject<any>({ loading: false, data: [] });
  private beneficiarios$ = new BehaviorSubject<any>({});
  private parentesco$ = new BehaviorSubject<any>([]);

  constructor(private http: HttpClient,
    private util: Util) { }

  setBeneficiarioSeleccionado(beneficiario) {
    this.beneficiarioSeleccionado$.next(beneficiario);
  }

  getBeneficiarioSeleccionado() {
    return this.beneficiarioSeleccionado$.asObservable();
  }

  getBeneficiarios() {
    return this.beneficiarios$.asObservable();
  }

  getParentesco() {
    return this.parentesco$.asObservable();
  }

  public obtenerBeneficiarios(rut:string) {
    const beneficiarioResponse = { loading: true, data: [] };
    this.beneficiarios$.next(beneficiarioResponse);
    let url = `${environment.BASE_BACKEND}/beneficiarios`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    // params = params.append('rut', rut);
    let body = { token: this.util.encrypt( rut ) }
    return this.http.post(url, body, { headers, params})
      .pipe(tap((response: any) => {
        beneficiarioResponse.data = response;
        beneficiarioResponse.loading = false;
        this.beneficiarios$.next(beneficiarioResponse);
      }, () =>  { 
        beneficiarioResponse.loading = false;
        this.beneficiarios$.next(beneficiarioResponse);
      }));
  }
  
  public eliminarBeneficiarios(id: number, rut:string, tipoCausalCese: string, ){
    const url = `${environment.BASE_BACKEND}/beneficiarios/${id}/eliminar`;
    const headers = new HttpHeaders();
    let params = new HttpParams();
    // params = params.append('rut', rut);
    // params = params.append('tipoCausalCese', tipoCausalCese);
    const data = { rut, tipoCausalCese };
    const body = { token: this.util.encrypt(JSON.stringify(data)) };
    return this.http.post( url, body, { headers, params} );
  }

  public editarBeneficiarios(id: number, rut: string, request: any ){
    const url = `${environment.BASE_BACKEND}/beneficiarios/${id}`;
    const headers = new HttpHeaders();
    let params = new HttpParams();
    params = params.append('rut', rut);
    // request['rut'] = rut;
    // let body = { token: this.util.encrypt( JSON.stringify( request ) ) }
    // console.log(body);
    return this.http.put( url, request, { headers, params} );
  }

  public obtenerParentesco(){
    let url = `${environment.BASE_BACKEND}/beneficiarios/parentesco`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.http.get(url, { headers, params})
    .pipe(tap(response => {
      this.parentesco$.next(response);
    }));
  }

}
