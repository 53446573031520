import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Util } from '@utils/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CartolaService {

  private cartolaMensual$ = new BehaviorSubject<any>({loading: false});
  
  constructor(private readonly http: HttpClient,
    private readonly util: Util) { }

  public obtenerCartolaMensual(rut: string){
    this.setCartolaMensual({loading: true});
    let url = `${environment.BASE_BACKEND}/cartola/mensual`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    let body = { token: this.util.encrypt( rut ) }
    this.http.post( url, body, { headers, params})
      .subscribe((response: any) => {
        const datosCartola = response ? response.DatosCartola : null;
        this.setCartolaMensual({loading: false, DatosCartola: datosCartola});
      }, (error) => {
        this.setCartolaMensual({ loading: false, error });
      })
  }

  public enviarCartolaMensual(params: any, download: boolean = false){
    let url = `${environment.BASE_BACKEND}/cartola/mensualpdf`;
    let headers = new HttpHeaders();
    params.download = download;
    const body = {
      token: this.util.encrypt(JSON.stringify(params))
    };
    return this.http.post(url, body, { headers });
  }

  setCartolaMensual(value) {
    this.cartolaMensual$.next(value);
  }

  getCartolaMensual() {
    return this.cartolaMensual$.asObservable();
  }
  
}
