import { Injectable } from '@angular/core';
import { environment } from '@env';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { Util } from '@utils/utils';
import _ from 'lodash';

import { IDatosPersonales, IDatosContacto, IDatosCuentaBancaria, IDatosBeneficiario } from "@interfaces/datos-personales.interface";
import { ICuenta, IBonoReconocimiento } from "@interfaces/cuentas-saldos.interface";
import { ICuentasCombo, IPeriodos } from "@interfaces/default.interface";
import { IMovimientos } from "@interfaces/movimientos.interface";
import { ITransacciones } from '@interfaces/transacciones.interface'
import { IMultiplicadorAportes } from "@interfaces/multiplicador-aportes.interface";
import { IPagoPension } from "@interfaces/pago-pension.interface";
import { IPeriodoCuatrimestral } from "@interfaces/cartolas.interface";
import { BeneficiariosService } from './beneficiarios.service';
import { CertificadosService } from '@services/certificados.service';

import { HOME_URL } from '@constants/url.constant';
import { tap, timeout } from 'rxjs/operators';
import { CartolaService } from './cartola.service';
import { RentabilidadService } from '@components/cuentas/rentabilidad.service';
import { BitacoraService } from '@services/bitacora.service'

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  // info
  public email: string = null; // no va a ser necesario
  public rutBuscado: string = null;
  public infoPersonal: IDatosPersonales = null;
  public infoContacto: IDatosContacto = null;
  public infoBeneficiarios: IDatosBeneficiario[] = null;
  public infoCuentaBancaria: IDatosCuentaBancaria[] = null
  public infoCuentasSaldos: ICuenta[] = null;
  public infoBonosReconocimiento: IBonoReconocimiento[] = null;
  public listmovimientos: IMovimientos[] = null;
  public infoTransacciones: ITransacciones[] = null;
  public infoPagoPension: IPagoPension = null;
  public infoPeriodosCuatrimestral: IPeriodoCuatrimestral[] = null;
  public estadoClave: string = null;
  public estadoClaveAcceso: string = null;

  // funcionales
  public cuentasCombo: ICuentasCombo[] = [];
  public cuentaSelected: string = '';
  public periodos: IPeriodos[] = [];
  public periodoSelected: string;
  public showPagoPension = false;
  public errorSearch = false;
  public errorSearchMgs = '';
  public searching: boolean = false;
  public valueSelected: string = 'datosPA'; // segment del homePage
  public searchingPagoPension: boolean = false;
  public validaIdentidadMsgShow: boolean = true;
  public validaDatosContacto: boolean = null;
  public busquedaActiva: boolean = false; // Flag para mostrar modal de cierre de atención en caso de que realizo una busqueda y no cerró atención, desplegar el modal
  public limpiaForm: boolean = false;
  private tipoCuentas$ = new BehaviorSubject<any>([]);
  private bancos$ = new BehaviorSubject<any>([]);

  constructor(
    private http: HttpClient,
    private navCtrl: NavController,
    private util: Util,
    private beneficiariosService: BeneficiariosService,
    public certificadosService: CertificadosService,
    private cartolaService: CartolaService,
    private readonly rentabilidadService: RentabilidadService,
    private readonly bitacoraSrvs: BitacoraService,
    private router: Router
  ) {
    // this.periodos = [ { valor: '12', descripcion: '12 meses' }, { valor: '18', descripcion: '18 meses' }, { valor: '24', descripcion: '24 meses' }, { valor: '36', descripcion: '36 meses' }, ];
    // this.periodoSelected = this.periodos[0].valor;
  }

  public claveProvisoria(rut: string, metodo: string): Observable<any> { //listo
    let url = `${environment.BASE_BACKEND}/claves/provisoria`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    // params = params.append('rut', rut);
    // params = params.append('metodo', metodo);
    const data = {
      rut,
      metodo
    };
    let body = { token: this.util.encrypt(JSON.stringify(data)) }

    return this.http.post(url, body, { headers, params });
  }

  public datosPersonales(rut: string): Observable<any> { //listo
    let url = `${environment.BASE_BACKEND}/cliente/datos-personales`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    let body = { token: this.util.encrypt(rut) }
    return this.http.post(url, body, { headers, params });
  }

  public editarDatosPersonales(rut: string, request: any): Observable<any> {
    let url = `${environment.BASE_BACKEND}/cliente/datos-personales`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    params = params.append('rut', rut);
    return this.http.put(url, request, { headers, params });
  }

  public eliminarCuentasBancarias(rut: string, request: any): Observable<any> {
    let url = `${environment.BASE_BACKEND}/cliente/informacion-bancaria/delete`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    // params = params.append('rut', rut);
    request['rut'] = rut;

    let body = { token: this.util.encrypt(JSON.stringify(request)) }
    return this.http.post(url, body, { headers, params }) as Observable<any>;
  }

  public agregarCuentasBancarias(rut: string, request: any): Observable<any> {
    let url = `${environment.BASE_BACKEND}/cliente/informacion-bancaria/add`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    // params = params.append('rut', rut);
    request['rut'] = rut;

    let body = { token: this.util.encrypt(JSON.stringify(request)) }
    return this.http.post(url, body, { headers, params }) as Observable<any>;
  }

  public editarCuentasBancarias(rut: string, request: any): Observable<any> {
    let url = `${environment.BASE_BACKEND}/cliente/informacion-bancaria/edit`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    // params = params.append('rut', rut);
    request['rut'] = rut;

    let body = { token: this.util.encrypt(JSON.stringify(request)) }
    return this.http.post(url, body, { headers, params }) as Observable<any>;
  }


  public datosCuentasBancarias(rut: string): Observable<IDatosCuentaBancaria[]> {
    let url = `${environment.BASE_BACKEND}/cliente/informacion-bancaria`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    let body = { token: this.util.encrypt(rut) }
    return this.http.post(url, body, { headers, params }) as Observable<IDatosCuentaBancaria[]>;
  }

  public obtenerTipoCuentas() {
    let url = `${environment.BASE_BACKEND}/cliente/informacion-bancaria/tipo-cuentas`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.http.get(url, { headers, params })
      .pipe(tap(response => {
        this.tipoCuentas$.next(response);
      }));
  }

  public obtenerBancos() {
    let url = `${environment.BASE_BACKEND}/cliente/informacion-bancaria/bancos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.http.get(url, { headers, params })
      .pipe(tap(response => {
        this.bancos$.next(response);
      }));
  }

  getBancos() {
    return this.bancos$.asObservable();
  }

  getTipoCuentas() {
    return this.tipoCuentas$.asObservable();
  }

  public datosBeneficiarios(rut: string) { //listo
    let url = `${environment.BASE_BACKEND}/beneficiarios`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    let body = { token: this.util.encrypt(rut) }
    return this.http.post(url, body, { headers, params });
  }

  public cuentas(rut: string): Observable<ICuenta[]> { //listo
    let url = `${environment.BASE_BACKEND}/cuentas`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    // params = params.append('rut', rut);
    let body = { token: this.util.encrypt(rut) }
    return this.http.post(url, body, { headers, params }) as Observable<ICuenta[]>;
  }

  public bonos(rut: string): Observable<IBonoReconocimiento[]> { //listo
    let url = `${environment.BASE_BACKEND}/cuentas/bono`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    // params = params.append('rut', rut);
    let body = { token: this.util.encrypt(rut) }
    return this.http.post(url, body, { headers, params }) as Observable<IBonoReconocimiento[]>;
  }

  public movimientos(rut: string, periodo: string, cuenta: string) { //listo

    let url = `${environment.BASE_BACKEND}/cuentas/movimientos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    // params = params.append('rut', rut);
    // params = params.append('tipoConsulta', 'CCP');
    // params = params.append('tipoProducto', cuenta);
    // params = params.append('numeroMeses', periodo);
    const data = {
      rut,
      tipoConsulta: 'CCP',
      tipoProducto: cuenta,
      numeroMeses: periodo
    };
    
    if (periodo === '0') {
      let fechas = this.util.fechasAnoPeriodo();

      data['fecha'] = fechas.fechaHasta;
    }

    let body = { token: this.util.encrypt(JSON.stringify(data)) }
    return this.http.post(url, body, { headers, params }) as Observable<any>
  }

  public transacciones(rut: string) { //listo
    let fechas = this.util.fechasAnoPeriodo();
    let cuentas: string[] = this.util.arrayCuentasCliente(this.cuentasCombo);
    let data = {
      fechaDesde: fechas.fechaDesde,
      fechaHasta: fechas.fechaHasta,
      cuentas: cuentas,
      rut
    }
    let body = { token: this.util.encrypt(JSON.stringify(data)) }
    let url = `${environment.BASE_BACKEND}/cuentas/transacciones`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    // params = params.append('rut', rut);

    return this.http.post(url, body, { headers, params }) as Observable<ITransacciones[]>
  }

  public transaccionDetalle(rut: string, numeroSolicitud: string, codigoSolicitud: string, codigoAgencia: string) { //listo

    let url = `${environment.BASE_BACKEND}/cuentas/transacciones/detalle`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    // params = params.append('rut', rut);
    // params = params.append('numeroSolicitud', numeroSolicitud);
    // params = params.append('codigoSolicitud', codigoSolicitud);
    // params = params.append('codigoAgencia', codigoAgencia);
    const data = {
      numeroSolicitud,
      codigoSolicitud,
      codigoAgencia,
      rut
    };
    let body = { token: this.util.encrypt(JSON.stringify(data)) }

    return this.http.post(url, body, { headers, params }) as Observable<any[]>
  }

  public multiplicadorAportes() { //listo
    let url = `${environment.BASE_BACKEND}/rentabilidad`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    // params = params.append('rut', rut);
    const rut = this.util.rutCleanAConnect(this.rutBuscado);
    let body = { token: this.util.encrypt(rut) }
    return this.http.post(url, body, { headers, params })
      .subscribe(response => {
        this.rentabilidadService.setRentabilidad(response);
      }, () => {
        const rentabilidad = { IdCliente: null, Rut: null, FecAfiliacionAdm: null, AntiguedadAdm: null, FecAfiliacionSistema: null, AntiguedadSistema: null, TotalMesesCotizados: null, Aportes: null, Cargos: null, ComisionFija: null, ComisionPorcentual: null, OtrosAportes: null, PrimasAportes: null, PrimasCargos: null, Retiros10: null, SaldoFinal: null, RentabilidadPesos: null, PorcentajeRentabilidad: null, AporteFinal: null, NegComisionPorcentual: null, OtrosCargosAportes: null, NegRetiros10: null, RentabilidadFinal: null, FactorSaldoAporteTotal: null, FactorSaldoAporte10: null, FactorRentAporteTotal: null, FactorRentAporte10: null, PeriodoDatos: null, PeriodoProceso: null, FechaCierreSaldos: null, DatosCertificado: null, VisibleParaCliente: null, IndCmh: null, FactorMultiplicador: null }
        this.rentabilidadService.setRentabilidad(rentabilidad);
      });
  }

  public pagoPension(rut: string) { //listo
    let url = `${environment.BASE_BACKEND}/pagos`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    // params = params.append('rut', rut);
    // params = params.append('movimientosAfiliado', 1);
    // params = params.append('periodoAfiliado', this.util.peridoPagoPension());
    // console.log(this.util.peridoPagoPension());
    const data = {
      rut,
      movimientosAfiliado: 1,
      periodoAfiliado: this.util.peridoPagoPension()
    };
    let body = { token: this.util.encrypt(JSON.stringify(data)) }

    return this.http.post(url, body, { headers, params }) as Observable<IPagoPension[]>
  }

  public certificado(tipoCertificado: number, params: any, download = false) { // listo

    let url = `${environment.BASE_BACKEND}/certificado/${tipoCertificado}`;
    let headers = new HttpHeaders();
    params['download'] = download;
    params['canal'] = environment.canal;
    // params = params.append('canal', environment.canal);
    let body = { token: this.util.encrypt(JSON.stringify(params)) }

    return this.http.post(url, body, { headers }) as Observable<any[]>
  }

  public base64toBlob(b64Data, sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: 'application/pdf' });
    return blob;
  }

  public periodosCuatrimestral(rut: string) {
    let url = `${environment.BASE_BACKEND}/cartola/periodos_cuatrimestral`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    // params = params.append('rut', rut);
    let body = { token: this.util.encrypt(rut) }
    return this.http.post(url, body, { headers, params }) as Observable<IPeriodoCuatrimestral[]>;
  }

  public enviarCartolaCuatrimestral(data: any, download: boolean = false) {

    let url = `${environment.BASE_BACKEND}/cartola/enviar_cuatrimestral`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    // params = params.append('rut', data.rut);
    // params = params.append('nombre', data.nombre);
    // params = params.append('email', data.email);
    // params = params.append('emailCC', data.emailCC);
    // params = params.append('nombreReporte', data.nombreReporte);
    // params = params.append('codigoNombreArchivo', data.codigoNombreArchivo);
    const request = {
      rut: data.rut,
      nombre: data.nombre,
      email: data.email,
      emailCC: data.emailCC,
      nombreReporte: data.nombreReporte,
      codigoNombreArchivo: data.codigoNombreArchivo,
      download: download
    }
    const body = { token: this.util.encrypt(JSON.stringify(request)) };
    return this.http.post(url, body, { headers, params }) as Observable<any>;
  }

  public obtenerEstadoClave(rut: string) {
    let url = `${environment.BASE_BACKEND}/claves/estado`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    // params = params.append('rut', rut);

    let body = { token: this.util.encrypt(rut) }
    return this.http.post(url, body, { headers, params }) as Observable<any>;
  }

  public obtenerEstadoClaveAcceso(rut: string) {
    let url = `${environment.BASE_BACKEND}/claves/estado-clave-acceso`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    // params = params.append('rut', rut);

    let body = { token: this.util.encrypt(rut) }
    return this.http.post(url, body, { headers, params }) as Observable<any>;
  }

  public datosContactoValida(rut: string): Observable<any> { //listo
    let url = `${environment.BASE_BACKEND}/cliente/datos-contacto-valida`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    let body = { token: this.util.encrypt(rut) }
    return this.http.post(url, body, { headers, params });
  }

  public cerrarAtencion(rut: string, request: any): Observable<any> {
    let url = `${environment.BASE_BACKEND}/cliente/cerrar-atencion`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    let body = { token: this.util.encrypt(rut), comentario: request.comentario }
    return this.http.post(url, body, { headers, params });
  }

  public cleanBuscar(logout = false) {
    // info
    this.email = null;
    this.rutBuscado = null;
    this.infoPersonal = null;
    this.infoContacto = null;
    this.infoBeneficiarios = null;
    this.infoCuentaBancaria = null
    this.infoCuentasSaldos = null;
    this.infoBonosReconocimiento = null;
    this.listmovimientos = null;
    this.infoTransacciones = null;
    this.infoPagoPension = null;
    this.infoPeriodosCuatrimestral = null;
    this.estadoClave = null;
    this.estadoClaveAcceso = null;
    // funcionales
    this.cuentasCombo = [];
    this.cuentaSelected = '';
    this.periodos = [];
    this.periodoSelected = null;
    this.errorSearch = false;
    this.errorSearchMgs = '';
    this.valueSelected = 'datosPA'; // segment del homePage
    this.showPagoPension = false;
    this.beneficiariosService.setBeneficiarioSeleccionado(null);
    this.validaIdentidadMsgShow = true;
    this.certificadosService.hayBeneficiario = null;
    this.validaDatosContacto = null;
    this.busquedaActiva = false;
    this.cartolaService.setCartolaMensual({loading: false, DatosCartola: null});
    this.bitacoraSrvs.cleanBitacoraSearch();
    this.navCtrl.navigateRoot(HOME_URL);

  }

  public buscar(rut: string) {
    this.cleanBuscar();
    this.searching = true;

    let cbFailure = error => {
      // console.log(error);
      this.searching = false;
      this.rutBuscado = rut;
      if (_.get(error, 'status', 500) === 401) {
        this.errorSearchMgs = 'El RUT ingresado no corresponde a una persona afiliada';
        this.errorSearch = true;
      } else {
        this.errorSearchMgs = _.get(error, 'error.errorMessage', 'error desconocido');
        this.errorSearch = true;
      }
    };
    let datosPersonalesOK = (response) => {
      // console.log('DatosPersonales: ', response);
      this.rutBuscado = rut;
      this.infoPersonal = {
        nombres: _.get(response, 'nombres', ''),
        apellidos: _.get(response, 'apellidoPaterno', '') + ' ' + _.get(response, 'apellidoMaterno', ''),
        edad: _.get(response, 'edad', '') + ' años',
        fechNacimiento: this.util.formatDateLong(_.get(response, 'fechaNacimiento', '')),
        sexo: _.get(response, 'sexo', ''),
        estadoCivil: _.get(response, 'estadoCivil', ''),
        claseCotizante: _.get(response, 'claseCotizante', ''),
        indicadorClienteFallecido: _.get(response, 'indicadorClienteFallecido'),
        apellidoMaterno: _.get(response, 'apellidoMaterno', ''),
        apellidoPaterno: _.get(response, 'apellidoPaterno', ''),
        fechaAfiliacionAFPCapital: this.util.formatDateLong(_.get(response, 'fechaAfiliacionAFPCapital', '')),
        fechaAfiliciacionSistema: this.util.formatDateLong(_.get(response, 'fechaAfiliciacionSistema', '')),
        fechaAfiliacionAFPCapitalDate: _.get(response, 'fechaAfiliacionAFPCapital', '')
      }
      this.infoContacto = {
        email: _.get(response, 'email', ''),
        telfMovil: _.get(response, 'telMovil', ''),
        telfFijo: _.get(response, 'codigoAreaTelefono', '') + _.get(response, 'telFijo', ''),
        direccion: _.get(response, 'direccion', ''),
        paisResidencia: _.get(response, 'paisResidencia', ''),
        region: _.get(response, 'region', ''),
        ciudad: _.get(response, 'ciudad', ''),
        comuna: _.get(response, 'comuna', ''),
        codigoComuna: _.get(response, 'codigoComuna', ''),
        codigoPaisResidencia: _.get(response, 'codigoPaisResidencia', ''),
        codigoRegion: _.get(response, 'codigoRegion', '')
      }
      this.email = _.get(response, 'email', '');

      this.searching = false;
      this.busquedaActiva = true;
      if (this.infoPersonal.claseCotizante === 'P') {
        this.showPagoPension = true;
        this.setPagoPension();
        this.certificadosService.compruebaBeneficiarios(this.util.rutCleanAConnect(rut));
        this.certificadosService.obtenerHayIngresoBase(this.util.rutCleanAConnect(rut));
      }
      this.setEstadoClaveAcceso();
      this.setEstadoClave();
      this.setPeriodosCuatrimestral();
      this.setCuentasBancarias();
      this.setBeneficiarios();
      this.setCuentas();
    };


    setTimeout(() => {
      this.datosPersonales(this.util.rutCleanAConnect(rut)).subscribe(datosPersonalesOK, cbFailure);
      
    }, 200);


    // if ( this.burquedaForm.status !== 'INVALID' ){
    //   this.cleanBuscar();
    // }else{
    //   console.log(this.burquedaForm.status);
    // }

  }

  get marcaPensionado() {
    return this.infoPersonal.indicadorClienteFallecido.toUpperCase() == 'S' ?
      'Afiliado fallecido' :
      this.infoPersonal.claseCotizante.toUpperCase() == 'A' ?
        'Afiliado activo' :
        this.infoPersonal.claseCotizante.toUpperCase() == 'P' ?
          'Pensionado'
          : '';
  }

  private setCuentasBancarias() {
    let cbFailure = error => {
      // console.log(error);
    };

    let cuentasOK = response => {
      // console.log( 'cuentas Bancarias ->', response);
      this.infoCuentaBancaria = response;
    };

    this.datosCuentasBancarias(this.util.rutCleanAConnect(this.rutBuscado)).subscribe(cuentasOK, cbFailure);
  }

  private setBeneficiarios() {
    this.beneficiariosService
      .obtenerBeneficiarios(this.util.rutCleanAConnect(this.rutBuscado))
      .subscribe();

    // let cbFailure = error => {
    //   this.infoBeneficiarios = [];
    // };

    // let beneficiariosOK = response  => {
    //   this.infoBeneficiarios = [];
    //   let beneficiarios: any[] = response;
    //   if (beneficiarios.length > 0){
    //     beneficiarios.forEach( item => {
    //       let beneficiario: IDatosBeneficiario = {
    //         rut: this.util.rutFormato(item.idBeneficiario.replace(/^(0+)/g, '')),
    //         nombre: item.nombres +' '+ item.apellidoPaterno +' '+ item.apellidoMaterno,
    //         parentesco: item.parentesco
    //       };
    //       this.infoBeneficiarios.push(beneficiario);
    //     });
    //   }
    // };

    // this.clienteSrvs.datosBeneficiarios(this.util.rutCleanAConnect(this.rutBuscado)).subscribe(beneficiariosOK, cbFailure);
  }

  private setPagoPension() {
    this.searchingPagoPension = true;
    let cbFailure = error => {
      this.searchingPagoPension = false;
      // console.log(error);
    };
    let pagoPensionOK = response => {
      let pagosPension: IPagoPension[] = response.InformacionPagos;
      if (pagosPension.length > 0) {
        this.infoPagoPension = pagosPension[0];
      }
      this.searchingPagoPension = false;
    }

    this.pagoPension(this.util.rutCleanAConnect(this.rutBuscado)).subscribe(pagoPensionOK, cbFailure);
  }

  private setCuentas() {
    this.cartolaService.setCartolaMensual({loading: true});

    let cuentasCombo: ICuentasCombo[] = [];

    let cbFailure = error => {
      // console.log(error);
      this.cartolaService.setCartolaMensual({loading: false});
    };

    let cuentasOK = response => {
      this.cartolaService.setCartolaMensual({loading: false});

      this.infoCuentasSaldos = response;
      this.infoCuentasSaldos.forEach(cuenta => {
        cuentasCombo.push({ producto: cuenta.producto, descripcionProducto: cuenta.descripcionProducto });
        cuenta.fondoRegimen.forEach(fondo => {
          fondo.verSubFondo = false;
        })
      });
      this.cuentasCombo = cuentasCombo;
      this.cuentaSelected = cuentasCombo[0].producto;
      
      const fechaAFP = this.infoPersonal.fechaAfiliacionAFPCapitalDate;
      const day = +fechaAFP.substring(0, 2);
      const month = +fechaAFP.substring(3, 5);
      const year = +fechaAFP.substring(6, 10);
      const fechaAfiliacion = new Date(year, month - 1, day);
      const noPensionado = this.marcaPensionado != 'Pensionado';
      const date = new Date()
      const periodo = new Date(date.setMonth(date.getMonth() - 6));
      if (noPensionado &&
        this.cuentasCombo &&
        this.cuentasCombo.some(x => x.producto == 'CCO') &&
        periodo >= fechaAfiliacion) {
        this.cartolaService.obtenerCartolaMensual(this.util.rutCleanAConnect(this.rutBuscado));
      }

      this.setmovimientos();
      this.setTransacciones();
      this.multiplicadorAportes();
    };

    this.cuentas(this.util.rutCleanAConnect(this.rutBuscado)).subscribe(cuentasOK, cbFailure);

    this.setBonoReconocimiento();
  }

  private setBonoReconocimiento() {
    let cbFailure = error => {
      // console.log(error);
    };
    let bonosOK = response => {
      // console.log('bono Reconocimiento -> ', response);
      this.infoBonosReconocimiento = response;
    };

    this.bonos(this.util.rutCleanAConnect(this.rutBuscado)).subscribe(bonosOK, cbFailure);
  }

  private setmovimientos() {
    this.periodos = [{ valor: '12', descripcion: '12 meses' }, { valor: '18', descripcion: '18 meses' }, { valor: '24', descripcion: '24 meses' }, { valor: '36', descripcion: '36 meses' }, { valor: '0', descripcion: 'todos' },];
    this.periodoSelected = this.periodos[0].valor;

    let cbFailure = error => {
      // console.log(error);
      this.listmovimientos = [];
    };
    let movimientosOK = response => {
      // console.log( response );
      this.listmovimientos = response;
    };

    this.movimientos(this.util.rutCleanAConnect(this.rutBuscado), this.periodoSelected, this.cuentaSelected).subscribe(movimientosOK, cbFailure);
  }

  private setTransacciones() {

    let cbFailure = error => {
      // console.log(error);
      this.infoTransacciones = [];
    };

    let transaccionesOK = response => {
      // console.log('transacciones ->>>', response);
      this.infoTransacciones = response;
    }

    this.transacciones(this.util.rutCleanAConnect(this.rutBuscado)).subscribe(transaccionesOK, cbFailure);
  }

  private setPeriodosCuatrimestral() {

    let periodosFail = error => {
      // console.log(error);
    }

    let periodosOk = response => {
      if (response.PeriodosCuatrimestralResponse) {
        this.infoPeriodosCuatrimestral = response.PeriodosCuatrimestralResponse.Periodos;
      }
    }

    this.periodosCuatrimestral(this.util.rutCleanAConnect(this.rutBuscado)).subscribe(periodosOk, periodosFail);
  }

  private setEstadoClave() {
    let estadoFail = error => {
      // console.log(error);
      this.estadoClave = ' error obteniendo estado '
    }

    let estadoOk = response => {
      if (response.estadoActual) {
        this.estadoClave = response.estadoActual.descripcion;
      }
    }

    this.obtenerEstadoClave(this.util.rutCleanAConnect(this.rutBuscado)).subscribe(estadoOk, estadoFail);
  }

  private setEstadoClaveAcceso() {
    let estadoFail = error => {
      // console.log(error);
      this.estadoClaveAcceso = ' error obteniendo estado '
    }

    let estadoOk = response => {
      if (response.EstadoClave) {
        this.estadoClaveAcceso = response.EstadoClave.descripcion;
      } else {
        this.estadoClaveAcceso = ' error obteniendo estado '
      }
    }

    this.obtenerEstadoClaveAcceso(this.util.rutCleanAConnect(this.rutBuscado)).subscribe(estadoOk, estadoFail);
  }


}
