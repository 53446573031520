
export const NUMBER_REGEX = new RegExp(/^[0-9]*$/);

export const EMAIL_REGEX = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');

export const ALLOWED_LENGTH_PASSWORD_VALIDATION = {
  min: 4,
  max: 4
};

export const ALLOWED_LENGTH_RUT_VALIDATION = {
  min: 2,
  max: 12
};

export const PRODUCTOS = [
  { codigo: 'CCO', nombre: 'Cotización Obligatoria', descripcion: 'Cuenta obligatoria' },
  { codigo: 'CAV', nombre: 'Cuenta de Ahorro (Cuenta 2)', descripcion: 'Cuenta 2' },
  { codigo: 'CCV', nombre: 'Cotización Voluntaria (APV)', descripcion: 'APV - Ahorro Previsional Voluntario' },
  { codigo: 'CDC', nombre: 'Depósito Convenido (CDC)', descripcion: 'Depósito Convenido (CDC)' },
  { codigo: 'CAF', nombre: 'Afiliado Voluntario (CAF)', descripcion: 'Afiliado Voluntario (CAF)' },
  { codigo: 'CAI', nombre: 'Cuenta de indemnización (CAI)', descripcion: 'Cuenta de indemnización (CAI)' },
  { codigo: 'CVC', nombre: 'APVC', descripcion: 'APVC' }
];

export const CERTIFICADOS_PENSIONADOS = [
  {tipo: 14, pideCuenta: false, pidePeriodo: false, nombre: 'Asignación familiar', descripcion: 'Acredita tus cargas familiares', fileName: 'asignacion_familiar'},
  {tipo: 15, pideCuenta: false, pidePeriodo: false, nombre: 'Calidad de pensionado', descripcion: 'Indica que eres pensionado/a de AFP Capital', fileName: 'calidad_pensionado'},
  {tipo: 16, pideCuenta: false, pidePeriodo: true, nombre: 'Pensiones pagadas', descripcion: 'Contiene los montos de pago de pensión durante un periodo de tiempo', fileName: 'pensiones_pagadas'},
  {tipo: 17, pideCuenta: false, pidePeriodo: false, nombre: 'Ingreso base', descripcion: 'Detalla el cálculo de tu pensión', fileName: 'ingreso_base'}
]

export const OTROS_CERTIFICADOS_BASE = [
  { tipo: 8, pideCuenta: false, pidePeriodo: true, nombre: 'Periodos no cotizados', descripcion: 'Detalla periodos en que no se pagaron tus cotizaciones obligatorias', fileName: 'periodos_no_cotizados' },
  { tipo: 9, pideCuenta: false, pidePeriodo: true, nombre: 'Trabajo pesado', descripcion: 'Certifica cotizaciones, tasa y años que se podrían rebajar de edad legal de pensión por trabajo pesado', fileName: 'trabajo_pesado' },
  { tipo: 10, pideCuenta: false, pidePeriodo: false, nombre: 'Suscripción APV', descripcion: 'Acredita tu APV y detalla la fecha de contratación en AFP Capital', fileName: 'suscripcion_apv' },
  { tipo: 11, pideCuenta: false, pidePeriodo: false, nombre: 'Suscripción Cuenta 2', descripcion: 'Acredita tu Cuenta 2 y detalla la fecha de contratación en AFP Capital', fileName: 'suscripcion_cav' },
  { tipo: 12, pideCuenta: false, pidePeriodo: false, nombre: 'Residencia', descripcion: 'Certifica tu domicilio, según lo registrado en nuestra base de datos', fileName: 'residencia' },
  { tipo: 13, pideCuenta: false, pidePeriodo: false, nombre: 'Traspasos de fondos', descripcion: 'Detalla cambios y distribuciones de fondos realizados en tus cuentas', fileName: 'traspasos_fondos' }
]

export const CERTIFICADOS_BASE = [
  { tipo: 1, pideCuenta: true, pidePeriodo: true, nombre: 'Certificado de cotizaciones', descripcion: 'Muestra todas las cotizaciones pagadas por tu empleador', fileName: 'cotizaciones' },
  { tipo: 2, pideCuenta: false, pidePeriodo: false, nombre: 'Antecedentes previsionales', descripcion: 'Detalla la información y saldos de tus cuentas en AFP Capital', fileName: 'antecedentes_previsionales' },
  { tipo: 3, pideCuenta: false, pidePeriodo: false, nombre: 'Afiliación al sistema previsional', descripcion: 'Indica el inicio de tus cotizaciones y su detalle durante períodos.', fileName: 'afiliacion' },
  { tipo: 4, pideCuenta: false, pidePeriodo: true, nombre: 'Remuneraciones imponibles', descripcion: 'Informa tus rentas, meses cotizados y su equivalente en años', fileName: 'remuneraciones_imponibles' },
  { tipo: 5, pideCuenta: false, pidePeriodo: false, nombre: 'Vacaciones progresivas', descripcion: 'Informa tus años trabajados dependiente a una empresa.', fileName: 'vacaciones_progresivas' },
  {
    tipo: 7, pideCuenta: false, pidePeriodo: true, nombre: 'Tributarios',
    descripcion: 'Informa sobre tus cuentas de ahorro voluntario por régimen tributario y año calendario, retiros por excedente de libre disposición y los datos necesarios para realizar y/o revisar declaración de impuestos.',
    fileName: 'tributarios'
  }
]

export const CERTIFICADOS_USOS = [
  { tipo: 1, usos: [ 'Pago de licencia médica', 'Beneficios de la garantía estatal', 'Incorporación a Fonasa, Isapre y Caja de compensación', 'Becas escolares', 'Tramitación de créditos', 'Apertura de productos bancarios y aumento de cupo', 'Acreditar renta' ] },
  { tipo: 2, usos: [ 'Para saber información y saldos de todas tus cuentas vigentes en AFP Capital', 'Acreditar domicilio' ] },
  { tipo: 3, usos: [ 'Acreditar ante un nuevo empleador incorporación a una AFP', 'Pago de licencia médica', 'Becas de estudios' ] },
  { tipo: 4, usos: [ 'Para saber información y saldos de todas tus cuentas vigentes en AFP Capital' ] },
  { tipo: 5, usos: [ 'Tener un mínimo de 10 años trabajados (120 meses cotizados)', 'Tener más de 3 años con el mismo empleador (últimas 36 cotizaciones)', 'Cada 36 cotizaciones un día extra de vacaciones', 'Este beneficio es intransferible', 'Cuando se cambia de empleador se parte desde cero' ] },
  { tipo: 7, usos: ['Revisar el impuesto a la renta', 'Corroborar si están correctos los fondos'] },
  {
    tipo: 8, usos: ['Trámites de pensión', 'Finiquitos', 'Beneficios del estado'] },
  { tipo: 9, usos: ['Determinar si el empleador debe realizar el pago de las cotizaciones de forma extra o normal', 'Revisar si el empleador está pagando correctamente las cotizaciones debido al trabajo pesado', 'Pensionarse antes de la edad legal'] },
  { tipo: 10, usos: ['Informar al empleador que tiene una APV, para que comience a descontar el monto del aporte mensual'] },
  { tipo: 11, usos: ['Informar al empleador que tiene una Cuenta 2, para que comience a descontar el monto del aporte mensual'] },
  { tipo: 12, usos: ['Acreditar domicilio'] },
  { tipo: 13, usos: ['Ver movimientos de fondos y rentabilidad'] },
  { tipo: 14, usos: ['Trámites de pensión', 'Finiquitos', 'Beneficios del estado', 'Incorporación a Fonasa o Isapre'] },
  { tipo: 15, usos: ['Trámites de pensión', 'Finiquitos', 'Beneficios del estado', 'Incorporación a Caja de compensación', 'Créditos'] },
  { tipo: 16, usos: ['Trámites de pensión', 'Finiquitos', 'Beneficios del estado', 'Acreditar renta', 'Créditos'] },
  { tipo: 17, usos: ['Trámites de pensión', 'Finiquitos', 'Beneficios del estado', 'Saber la renta qué se utilizó para el cálculo de tu pensión'] }
];

export const TIPOS_PRODUCTO_CERTIFICADO = [
  { codigo: 'CCO', tipoProducto: 3 },
  { codigo: 'CAV', tipoProducto: 2 },
  { codigo: 'CCV', tipoProducto: 4 },
  { codigo: 'CDC', tipoProducto: 5 },
  { codigo: 'CAF', tipoProducto: 6 },
  { codigo: 'CAI', tipoProducto: 1 },
  { codigo: 'CVC', tipoProducto: 7 }
]

export const MESES = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ]


export const ZONAS = [ 'Norte', 'Centro', 'Metropolitana', 'Sur', 'Austral' ];

export const ORDEN_DESPLIEGUE_CARTOLA_MENSUAL = ['CCO', 'CAV', 'CCV', 'CDC']

export const API_BANK_STATUS_CODES = {
  valid: 'A',
  invalid: 'R'
}
